<template>
  <div id="main-content" class="main-content-normal" :class="{'main-content-bigger':$store.state.mainBig}">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row clearfix">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <h1>
              <!-- 电站报表 -->
              {{ $t("history.pshistoryQuery") }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row clearfix mt-4">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="row clearfix">
                <div class="col-lg-12 col-lg-auto">
                  <div class="row clearfix">
                    <div class="col-md-4 col-md-auto">
                      <select-ps-id-view :cls="'col-12'" @psIdChange="handlePsIdChange" />
                    </div>
                    <el-col class="col-md-2 col-md-auto text-left">
                      <el-tooltip class="item" :content="$t('charts.chartsxzrb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button :type="primaryArr.days.primaryDay" @click="chageTypeCalendar" circle v-html="$t('history.DatePicker.ChooseDay')">日</el-button>
                      </el-tooltip>
                      <el-tooltip class="item " :content="$t('charts.chartsxzyb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button :type="primaryArr.month.primaryMonth" @click="chageTypeCalendar" circle v-html="$t('history.DatePicker.ChooseMonth')">月</el-button>
                      </el-tooltip>
                    </el-col>
                    <div class="col-md-6 col-md-auto m-b--5 text-right">
                      <el-date-picker v-model="valueson1" @change="dateSreach" :picker-options="pickerOptions" :clearable="false" :editable="false" :value-format="calendarArr.format" :type="calendarArr.type" :placeholder="$t('charts.changeTime')" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="col-lg-12 col-lg-auto">
                <div class="row clearfix">
                  <div class="col-md-2 col-md-auto">

                  </div>
                  <div class="col-md-10 col-md-auto m-b--5 text-right">
                    <div class="demo-input-suffix">
                      <el-checkbox-group size="medium" v-show="showLoading" @change="search" v-model="value1" :placeholder="$t('charts.parameterType')">
                        <el-checkbox-button v-for="item in options" :key="item.value" :label="$t(item.label)" :value="item.value" :index="item.index" v-show="item.visible">{{$t(item.label)}}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="card-header">
              <div class="row clearfix"> 
                <div class="col-lg-7 col-lg-auto">
                  <div class="row clearfix">
                    <div class="col-md-4 col-md-auto">
                      <select-ps-id-view :cls="'col-12'" @psIdChange="handlePsIdChange" />
                    </div>
                    <div class="col-md-8 col-md-auto text-right">
                      <group-device-type :cls="'col-12'" :all-show="false" :psId="psId" :selDeviceTypeId="seldevicetypeid" @deviceTypeIdChecked="handleDeviceTypeChange" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-lg-auto m-b--20 pr-3 text-right">
                  <el-select v-model="value1" multiple :placeholder="$t('charts.parameterType')" class="col-5 text-right" float="right">
                    <el-option v-for="item in options" :key="item.value" :label="$t(item.label)" :value="item.value" :index="item.index">
                    </el-option>
                  </el-select>
                  <el-date-picker v-model="valueson1" :picker-options="pickerOptions" :clearable="false" :editable="false" value-format="yyyy-MM-dd" type="date" :placeholder="$t('charts.changeTime')" float="right" class="col-3">
                  </el-date-picker>
                  <el-tooltip class="item ml-1" :content="$t('charts.search')" placement="bottom" popper-class="dark_aooltip" float="right">
                    <el-button type="primary" icon="fad fa-search" circle @click="search"></el-button>
                  </el-tooltip>
                </div>
              </div>
            </div> -->
            <div class="card-body">
              <div class="col-lg-12" v-if="checkDeviceTypeId == 'loading'">
                <!-- 请选择要对比的设备类型 -->
                {{ $t("charts.deviceType") }}
              </div>
              <ps-chars v-show="checkDeviceTypeId != 'loading'" :sreachTypes="sreachType" :calendarTypes="calendarArr.type" :checkDeviceTypeIds="checkDeviceTypeId" :searchDate="valueson1" :searchBtn="bntNumber" :chooseDatas="keyDatas" :params="params" @dataLoading="handleDataLoading" />
              <!-- <pcs-chars v-show="checkDeviceTypeId == 1"
                         :chekcDeviceTypeId="checkDeviceTypeId"
                         :searchBtn="bntNumber"
                         :params="params"
                         :searchDate="valueson1"
                         @dataLoading="handleDataLoading" />
              <dc-dc-chars v-show="checkDeviceTypeId == 2"
                           :chekcDeviceTypeId="checkDeviceTypeId"
                           :searchBtn="bntNumber"
                           :params="params"
                           :searchDate="valueson1"
                           @dataLoading="handleDataLoading" />
              <bms-chars v-show="checkDeviceTypeId == 3"
                         :chekcDeviceTypeId="checkDeviceTypeId"
                         :searchBtn="bntNumber"
                         :params="params"
                         :searchDate="valueson1"
                         @dataLoading="handleDataLoading" />
              <ari-chars v-show="checkDeviceTypeId == 4"
                         :chekcDeviceTypeId="checkDeviceTypeId"
                         :searchBtn="bntNumber"
                         :params="params"
                         :searchDate="valueson1"
                         @dataLoading="handleDataLoading" />
              <div class="col-lg-12"
                   v-if="checkDeviceTypeId == 5">
                {{ $t("charts.notSupport") }}
              </div>
              <div class="col-lg-12"
                   v-if="checkDeviceTypeId == 6">
                {{ $t("charts.notSupport") }}
              </div> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ButtongroupView from '../components/ButtongroupView'
import SelectPsIdView from "../components/HistoryView/SelectPsId.vue";
import PsChars from "../components/ChartView/PsChars.vue";
// import AriChars from "../components/ChartView/AriChars.vue";
// import PcsChars from "../components/ChartView/PcsChars.vue";
// import DcDcChars from "../components/ChartView/DcDcChars.vue";
// import BmsChars from "../components/ChartView/BmsChars.vue";


export default {
  components: {
    SelectPsIdView,
    PsChars
  },
  name: "chart",
  mounted () {
    const chartDom = document.getElementById("EquipmentComparison-Chart");
    const chart = this.$echarts.init(chartDom, 'macarons');
    chart.setoption({
      xAxis: {},
      yAxis: {},
      series: [
        {
          type: "line",
          data: []
        }
      ]
    });
  },


  data () {
    return {
      setParamsButton: true,
      setPsFlag: false,
      callnum: "",
      calendarArr: { "format": "yyyy-MM-dd", "type": "date" },
      primaryArr: {
        "days": {
          "primaryDay": "primary"
        }, "month": {
          "primaryMonth": ""
        }
      },
      sreachType: "",
      deviceTypeArr: [],
      keyDatas: [],
      visibleValue: [],
      olddeviceTypeId: null,
      oldsearchdate: null,
      showLoading: true,
      psId: null,
      seldevicetypeid: null,
      checkDeviceTypeId: null,
      loading: false,
      deviceTypeList: [],
      otimer: null,
      isload: false,
      st: { opacity: 1 },
      options: [],
      optionsPara: [],
      valueson1: "",
      value1: [],
      value2: [],
      sizeForm: {
        region: ""
      },
      params: [],
      bntNumber: 0,
      pickerOptions: {
        disabledDate (time) {
          let buildDate = window.sessionStorage.getItem("buildDate");
          console.log(buildDate);
          let dts = buildDate.split("-");
          let dt = new Date(
            parseInt(dts[0]),
            parseInt(dts[1]) - 1,
            parseInt(dts[2]) - 1
          );
          return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
        }
      },

    };
  },
  watch: {
    '$store.state.languageId': function () {
      this.bntNumber = Math.floor(Math.random() * 999999);
    }
  },
  mounted () {
    this.sreachType = "ps";
    this.getParamsButton();
    this.oldsearchdate = this.formatDate(new Date().getFullYear(), (new Date().getMonth() + 1), new Date().getDate());
    this.valueson1 = this.oldsearchdate;
    //通过路由获取传值的deviceId和deviceTypeId;
    if (this.$route.params.deviceTypeId != undefined && this.$route.params.deviceTypeId != "") {
      this.seldevicetypeid = this.$route.params.deviceTypeId;
      //this.handleDeviceTypeChange(this.seldevicetypeid);
    }
    // this.init();
    // this.getPsInfos();
  },
  methods: {
    //切换月和日的日历控件
    chageTypeCalendar () {
      //alert(this.typeCalendar)
      this.setParamsButton = false;
      this.deviceTypeArr = [];
      this.sreachType = "ps";
      if (this.calendarArr.format == "yyyy-MM-dd") {
        this.calendarArr.format = "yyyy-MM";
        this.calendarArr.type = "month";
        this.primaryArr.days.primaryDay = "";
        this.primaryArr.month.primaryMonth = "primary";
        this.valueson1 = this.formatDate(parseInt(new Date().getFullYear()), parseInt(new Date().getMonth() + 1), 1);
      }
      else {
        this.calendarArr.format = "yyyy-MM-dd";
        this.calendarArr.type = "date";
        this.primaryArr.days.primaryDay = "primary";
        this.primaryArr.month.primaryMonth = "";
        this.valueson1 = this.formatDate(parseInt(new Date().getFullYear()), parseInt(new Date().getMonth() + 1), parseInt(new Date().getDate()))
      }
      this.options = [];
      this.value1 = [];
      this.checkDeviceTypeId = null;
      this.callnum = Math.floor(Math.random() * 999999);
      this.getParamsButton();
      if (this.checkDeviceTypeId == null || this.checkDeviceTypeId == "") {
        this.checkDeviceTypeId = "";
        this.paramSetChoose();
        this.bntNumber = Math.floor(Math.random() * 999999);
      }

      //if (this.checkDeviceTypeId != null && this.checkDeviceTypeId != "" && this.checkDeviceTypeId.indexOf(",") >= 0) {
      //this.bntNumber = Math.floor(Math.random() * 999999);
      //}
    },
    handleDataLoading (flag) {
      this.showLoading = flag;
    },
    initParams () {
      for (let i = 0; i < this.options.length; i++) {
        this.visibleValue[i] = this.options[i]["value"]
      }
    },
    //更换日期时查询数据
    dateSreach () {
      if (this.checkDeviceTypeId != "loading") {
        //this.params = [];
        this.value1 = [];
        if (this.oldsearchdate != this.valueson1) {
          this.oldsearchdate = this.valueson1;
          this.bntNumber = Math.floor(Math.random() * 999999);
        }
      }
    },
    handlePsIdChange (psBuilddate, psId,psName) {
      this.checkDeviceTypeId = "";
      this.sreachType = "ps";
      this.setPsFlag = true;
      window.sessionStorage.setItem("buildDate", psBuilddate);
      window.sessionStorage.setItem("psName", psName);
      this.$store.state.buildDate = psBuilddate;
      this.$emit("parentPsId");
      this.psId = psId;
      this.deviceTypeArr = [];
      //this.valueson1 = this.formatDate(new Date().getFullYear(), (new Date().getMonth() + 1), new Date().getDate());
      this.paramSetChoose();
      this.bntNumber = Math.floor(Math.random() * 999999);
    },
    handleDeviceTypeChange (deviceTypeId) {
      if (this.sreachType == "ps" && this.setPsFlag) {
        deviceTypeId = "";
        this.setPsFlag = false;
      }

      if (deviceTypeId != undefined && deviceTypeId != "")
        this.sreachType = "dev";
      else
        this.sreachType = "ps";

      if (this.olddeviceTypeId != deviceTypeId) {
        this.showLoading = false;
      }

      for (let i = 0; i < this.options.length; i++) {
        this.options[i]["visible"] = false;
      }
      if (deviceTypeId == undefined || deviceTypeId == "") {
        this.value1 = [];
      } else {
        let devTypeId = "";
        let arr = new Array();
        arr = deviceTypeId.split(",");
        arr.splice(arr.length - 1, 1)
        arr = arr.sort();
        this.deviceTypeArr = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] != undefined && arr[i] != "") {
            this.deviceTypeArr.push(arr[i]);
          }
        }
        this.deviceTypeArr = this.unique(this.deviceTypeArr)
        this.deviceTypeArr = this.deviceTypeArr.sort();
        for (let i = 0; i < this.deviceTypeArr.length; i++) {
          devTypeId = devTypeId + this.deviceTypeArr[i] + ","
        }
        deviceTypeId = devTypeId
      }
      this.checkDeviceTypeId = deviceTypeId;

      if (this.setParamsButton && this.callnum == "") {
        this.paramSetChoose();
      }
      if (!this.setParamsButton) {
        this.checkDeviceTypeId = null;
        this.setParamsButton = true;
      }
      if (this.callnum != "") {
        this.callnum = "";
      }


      if (this.checkDeviceTypeId != null && this.checkDeviceTypeId != "loading") {
        if (this.checkDeviceTypeId != "") {
          this.value1 = [];
        }
        this.oldsearchdate = this.valueson1;
        this.bntNumber = Math.floor(Math.random() * 999999);
      }

      if (this.olddeviceTypeId == this.checkDeviceTypeId && this.oldsearchdate == this.valueson1) {
        return false;
      } else {
        this.olddeviceTypeId = this.checkDeviceTypeId;
        this.oldsearchdate = this.valueson1;
      }

      //this.valueson1 = this.formatDate(new Date().getFullYear(), (new Date().getMonth() + 1), new Date().getDate());
    },
    //根据选择设备找到查询的数据字段
    paramSetChoose () {
      let arr;
      this.optionsPara = [];
      this.visibleValue = [];

      if (this.calendarArr.type == "date") {
        switch (this.checkDeviceTypeId) {
          case "":
            this.visibleValue = ["param1"];
            //电站类型削峰3，没有光伏发电功率
            if (this.$store.state.psTypeId != "3") {
              this.optionsPara.push([{
                "deviceTypeId": "",
                "param1": [{
                  label: "charts.dcdc.pvPower",
                  value: "pvPac",// 光伏发电功率
                  visible: false,
                  index: 1,
                  group: 0,
                }]
              }]);
            }

            this.optionsPara.push([{
              "deviceTypeId": "",
              "param1": [{
                label: "charts.pcs.inOutPower",
                value: "pac",//充放/电功率
                visible: false,
                index: 2,
                group: 0,
              }]
            }]);
            //电站类型过积2,AC削峰，没有用户电表功率
            if (this.$store.state.psTypeId != "2" && this.$store.state.psTypeId != "3") {
              this.optionsPara.push([{
                "deviceTypeId": "",
                "param1": [{
                  label: "charts.ammter.gridOrSend",
                  value: "gridPac",//用户电表功率
                  visible: false,
                  index: 3,
                  group: 0,
                }]
              }]);
            }
            if (this.$store.state.psTypeId != "2") {
              this.optionsPara.push([{
                "deviceTypeId": "",
                "param1": [{
                  label: "charts.overload.overPower",
                  value: "powerCon",//消费功率
                  visible: false,
                  index: 4,
                  group: 0,
                }]
              }]);
            }

            this.optionsPara.push([{
              "deviceTypeId": "",
              "param1": [{
                label: "charts.bms.soc",
                value: "soc",//Soc
                visible: false,
                index: 4,
                group: 1,
              }]
            }]);
            this.value1 = [this.$t("charts.alike.power")];
            this.search();
            //this.bntNumber = this.bntNumber = Math.floor(Math.random() * 999999);
            break;
          default:
            this.visibleValue = [];
            this.optionsPara = [];
            break;
        }
      } else if (this.calendarArr.type == "month") {
        switch (this.checkDeviceTypeId) {
          case "":
            //电站类型是过积载或削峰（AC），不显示买电量
             if (this.$store.state.psTypeId != "2" && this.$store.state.psTypeId != "3") {
                  this.visibleValue = ["param1"];
                  this.optionsPara.push([{
                    "deviceTypeId": "",
                    "param1": [{
                      label: "charts.ammter.gridEngery",
                      value: "gridEngery",//买电量
                      visible: false,
                      index: 0,
                      group: 0,
                    }]
                  }]);
             }
              //电站类型是过积载，不显示放电量
               if (this.$store.state.psTypeId != "2"){
                 this.optionsPara.push([{
                      "deviceTypeId": "",
                      "param1": [{
                        label: "charts.pcs.outTotal",
                        value: "outTotal",//放电量
                        visible: false,
                        index: 1,
                        group: 0,
                      }]
                   }]);
               }
             

            //电站类型削峰3，没有光伏发电量
            if (this.$store.state.psTypeId != "3") {
              this.optionsPara.push([{
                "deviceTypeId": "",
                "param1": [{
                  label: "charts.dcdc.pvTotal",
                  value: "pvTotal",//光伏发电量
                  visible: false,
                  index: 2,
                  group: 0,
                }]
              }]);
            }



            //电站类型过积载2，没有消费电量和卖电量
            if (this.$store.state.psTypeId != "2") {
              this.optionsPara.push([{
                "deviceTypeId": "",
                "param1": [{
                  label: "charts.overload.overPac",
                  value: "totalpac",//消费电量
                  visible: false,
                  index: 3,
                  group: 0,
                }]
              }]);
            }
               //电站类型是过积载或削峰（AC），没有卖电量
              if (this.$store.state.psTypeId != "2" && this.$store.state.psTypeId != "3") {
                    this.optionsPara.push([{
                      "deviceTypeId": "",
                      "param1": [{
                        label: "charts.ammter.Send",
                        value: "sendEngery",//卖电量
                        visible: false,
                        index: 4,
                        group: 0,
                      }]
                    }]);
              }


            //电站类型过积载2，value=totalpac
            if (this.$store.state.psTypeId == "2") {
               this.optionsPara.push([{
                "deviceTypeId": "",
                "param1": [{
                  label: "charts.ammter.Send",
                  value: "totalpac",//卖电量
                  visible: false,
                  index: 4,
                  group: 0,
                }]
              }]);
            }
           

            //电站类型过积载2，没有充电量
            if (this.$store.state.psTypeId != "2") {
            this.optionsPara.push([{
              "deviceTypeId": "",
              "param1": [{
                label: "charts.pcs.inTotal",
                value: "inTotal",//充电量
                visible: false,
                index: 5,
                group: 0,
              }]
            }]);
            }
            this.value1 = [this.$t("charts.alike.electricity")];
            this.search();
            break;
          default:
            this.visibleValue = [];
            this.optionsPara = [];
            break
        }
      }

      if (this.setParamsButton) {
        this.paramSetView();
      }
    },
    paramSetView () {
      for (let i = 0; i < this.options.length; i++) {
        for (let j = 0; j < this.visibleValue.length; j++) {
          if (this.options[i]["value"] == this.visibleValue[j]) {
            this.options[i]["visible"] = true;
          }
        }
      }
    },
    search () {
      if (this.checkDeviceTypeId == "loading") {
        // 请选择对比类型
        //return this.$message.warning("请选择对比类型")
        //swal(this.$t('charts.type'), "", "warning");
        swal({
          title: this.$t("charts.type"),
          text: "",
          type: "warning",
          confirmButtonText: this.$t("charts.msg.confirm")
        });
        return false;
      }

      if (this.value1.length == 0) {
        // 请选择对比参数
        // swal({
        //   title: this.$t("charts.parameter"),
        //   text: "",
        //   type: "warning",
        //   confirmButtonText: this.$t("charts.msg.confirm")
        // });
        this.params = [];
        return false;
      }

      if (this.valueson1 == "") {
        //return this.$message.warning("请选择对比时间")
        // 请选择对比时间
        //swal(this.$t('charts.time'), "", "warning");
        swal({
          title: this.$t("charts.time"),
          text: "",
          type: "warning",
          confirmButtonText: this.$t("charts.msg.confirm")
        });
        return false;
      }

      let sortpara = [];

      console.info("value1", this.value1)

      let optionsArr = [];
      for (let i = 0; i < this.value1.length; i++) {
        for (let j = 0; j < this.options.length; j++) {
          if (this.$t(this.options[j]["label"]) == this.value1[i]) {
            optionsArr[i] = this.options[j]["value"]

            if (this.checkDeviceTypeId != "" && this.checkDeviceTypeId.indexOf(",") >= 0) {//查看设备级数据
              let arr = new Array();
              arr = this.checkDeviceTypeId.split(",");

              for (let k = 0; k < arr.length; k++) {
                let josnObj = {};
                if (arr[k] != undefined && arr[k] != "") {
                  for (var a = 0; a < this.optionsPara.length; a++) {
                    if (this.optionsPara[a][0]["deviceTypeId"] == arr[k]) {
                      let devValue = this.options[j]["value"];
                      josnObj["deviceTypeId"] = arr[k];
                      josnObj[devValue] = this.optionsPara[a][0][devValue];
                      sortpara.push(josnObj)
                      if (this.checkDeviceTypeId == "3,") {
                        for (var b = 0; b < this.optionsPara[a][0][devValue].length; b++) {
                          if (this.optionsPara[a][0][devValue][b]["rack"] != null) {
                            josnObj = {};
                            josnObj["deviceTypeId"] = arr[k];
                            josnObj[devValue] = this.optionsPara[a][0][devValue][b]["rack"];
                            josnObj["deviceTypeName"] = "rack";
                            sortpara.push(josnObj)
                          }
                        }
                      }
                    }
                  }

                }
              }
            } else {//电站级数据
              for (var a = 0; a < this.optionsPara.length; a++) {
                let josnObj = {};
                josnObj["deviceTypeId"] = "";
                josnObj[this.options[j]["value"]] = this.optionsPara[a][0][this.options[j]["value"]];
                sortpara.push(josnObj)
              }
            }
          }
        }
      }

      console.info("sortpara", sortpara)

      this.keyDatas = this.options;
      this.params = sortpara;
    },
    getParamsButton () {
      switch (this.calendarArr.type) {
        case "month":
          this.options.push({
            label: "charts.alike.electricity",
            value: "param1",
            visible: false,
          });
          this.options.push({
            label: "charts.alike.voltage",
            value: "param2",
            visible: false,
          });
          this.options.push({
            label: "charts.alike.temp",
            value: "param3",
            visible: false,
          });
          break;
        case "date":
          this.options.push({
            label: "charts.alike.power",
            value: "param1",
            visible: false,
          });
          this.options.push({
            label: "charts.alike.voltage",
            value: "param2",
            visible: false,
          });
          this.options.push({
            label: "charts.alike.temp",
            value: "param3",
            visible: false,
          });
          this.options.push({
            label: "charts.alike.soc",
            value: "param4",
            visible: false,
          });
          break;
      }

      this.initParams();
    },
    //数组对象排序
    sortByKey (array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      })
    },
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    },
    unique (array) {
      let n = {}, r = [], len = array.length, val, type;
      for (let i = 0; i < array.length; i++) {
        val = array[i];
        type = typeof val;
        if (!n[val]) {
          n[val] = [type];
          r.push(val);
        } else if (n[val].indexOf(type) < 0) {
          n[val].push(type);
          r.push(val);
        }
      }
      return r;
    },
   
  }
};
</script>

<style></style>
