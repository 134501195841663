<template>
  <div class="tab-pane show vivify fadeIn active"
       style="height:580px;"
       v-loading="loading">
    <div id="device-indoor-temp-char-detail"
         :style="{width:'100%',height:'96%','text-align':'center','line-height':'400px'}">{{$t("history.noData")}}</div>

         <div class="col-md-12  text-center" style="margin-top:5px;">
                <el-button type="aurora"  :disabled="psReportParam.isDis" :loading="psReportParam.loading" class="is-active" size="small" @click="downPsReport()">{{ $t('ReportDownloads.title') }}</el-button>
            </div>
  </div>
</template>
<script>
import {getMaxMinValue,getMaxMinSet} from '../../utils/tools.js'
export default {
  data () {
    return {
      startdate: this.formatDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()) + " 00:00:00",
      enddate: this.formatDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()) + " 23:59:59",
      calendarType: "date",
      searchdate: null,
      sreachType: "ps",
      deviceTypeIds: null,
      loading: false,
      myChart: null,
      charDatas: [],
      paraDatas: [],
      keyDatas: [],
      keyParams: null,
      maxValueKW: 0,
      minValueKW: 0,
      maxValueSoc: 0,
      minValueSoc: 0,
      units: [
        { "deviceTypeId": "", "unit": { "pvPac": "kW", "pac": "kW", "gridPac": "kW", "powerCon": "kW", "soc": "%", "gridEngery": "kW", "outTotal": "kW", "pvTotal": "kW", "sendEngery": "kW", "InTotal": "kW" } },
        { "deviceTypeId": 1, "unit": { "pac": "kW", "rVac": "V", "sVac": "V", "tVac": "V", "itemp": "°C", "inTotal": "kW", "outTotal": "kW" } },
        { "deviceTypeId": 2, "unit": { "bcp": "kW", "ppv": "kW", "vpv": "V", "pvTotal": "kW" } },
        { "deviceTypeId": 3, "unit": { "bcp": "kW", "bv": "V", "bvmax": "V", "bvmin": "V", "btMax": "°C", "btMin": "°C", "btmax": "°C", "btmin": "°C", "soc": "%", "mcpList": "kW", "mvMaxList": "V", "mvMinList": "V", "mtMaxList": "°C", "mtMinList": "°C", "msocList": "%" } },
        { "deviceTypeId": 4, "unit": { "temp": "°C", "indoortemp": "°C", "humidity": "RH", "maxindoortemp": "°C", "minindoortemp": "°C" } },
        { "deviceTypeId": 5, "unit": { "pac": "kW", "gridEngery": "kW", "sendEngery": "kW", "electricityCon": "kW" } },
      ],
      yAxisNams: [{
        "deviceTypeId": "",
        "yAxisName":
        {
          "kW": 'charts.pcs.inOutPower',//"功率",
          "%": 'charts.bms.soc',//"soc"
        }
      }, {
        "deviceTypeId": 1,
        "yAxisName":
        {
          "kW": 'charts.pcs.inOutPower',//"功率",
          "V": 'charts.pcs.voltage',//"电压",
          "A": 'charts.pcs.current',//"电流",
          "°C": 'charts.pcs.temperature',//"温度"
        }
      }, {
        "deviceTypeId": 2,
        "yAxisName":
        {
          "kW": 'charts.dcdc.power',//"功率",
          "V": 'charts.dcdc.voltage',//"电压",
          "A": 'charts.dcdc.current',//"电流"，
          "°C": 'charts.dcdc.temperature',//"温度"
        }
      }, {
        "deviceTypeId": 3,
        "yAxisName":
        {
          "kW": 'charts.bms.power',//"功率",
          "V": 'charts.bms.voltage',//"电压",
          "A": 'charts.bms.current',//"电流",
          "%": 'charts.bms.soc',//"soc"
          "°C": 'charts.bms.temperature',//"温度"
        }
      }, {
        "deviceTypeId": -3,
        "yAxisName":
        {
          "kW": 'charts.rack.power',//"功率",
          "V": 'charts.rack.voltage',//"电压",
          "A": 'charts.rack.current',//"电流",
          "%": 'charts.rack.soc',//"soc"
          "°C": 'charts.rack.temperature',//"温度"
        }
      }, {
        "deviceTypeId": 4,
        "yAxisName":
        {
          "kW": 'charts.air.power',//"功率",
          "V": 'charts.air.voltage',//"电压",
          "A": 'charts.air.current',//"电流",
          "°C": 'charts.air.temperature',//"温度"
        }
      }, {
        "deviceTypeId": 5,
        "yAxisName":
        {
          "kW": 'charts.ammter.power',//"功率",
          "V": 'charts.ammter.voltage',//"电压",
          "A": 'charts.ammter.current',//"电流",
          "°C": 'charts.ammter.temperature',//"温度"
        }
      }],
      psReportParam:{
        name:"",
        title:[],
        val:[],
        result:[],
        loading:false,
        isDis:true,
      }
    }
  },
  props: ['calendarTypes', 'sreachTypes', 'checkDeviceTypeIds', 'searchDate', 'searchBtn', 'params', 'chooseDatas'],
  watch: {
    calendarTypes: {
      handler (calendarTypes) {
        this.calendarType = calendarTypes;
        if (this.myChart != null) {
          this.myChart.setOption({});
          //没有参数销毁图表对象
          this.myChart.dispose();
        }
      }
    },
    sreachTypes: {
      handler (sreachTypes) {
        this.sreachType = sreachTypes;
        if (this.myChart != null) {
          this.myChart.setOption({});
          //没有参数销毁图表对象
          this.myChart.dispose();
        }
      }
    },
    checkDeviceTypeIds: {
      handler (checkDeviceTypeIds) {
        if (checkDeviceTypeIds != "loading") {
          this.deviceTypeIds = checkDeviceTypeIds;
        }
      }
    },
    searchDate: {
      handler (searchDate) {
        if (searchDate != null) {
          this.searchdate = searchDate;
          this.charDatas = [];
          if (this.searchdate.indexOf(" ") >= 0) {
            this.searchdate = this.searchdate.split(" ")[0];
          }
          if (this.searchdate.indexOf("-") >= 0) {
            if (this.searchdate.split("-").length == 2) {
              this.searchdate = this.searchdate + "-01";
            }
          }
          if (this.myChart != null) {
            this.myChart.setOption({});
            //没有参数销毁图表对象
            this.myChart.dispose();
          }
        }
      }
    },
    chooseDatas: {
      handler (chooseDatas) {
        this.keyDatas = chooseDatas;
      }
    },
    params: {
      handler (params) {
        this.keyParams = params;
        if (this.sreachType == "dev") {
          if (this.keyParams != null && this.keyParams.length > 0) {
            this.detailChar();
          } else {
            // if (this.myChart != null) {
            //   this.myChart.setOption({});
            //   //没有参数销毁图表对象
            //   this.myChart.dispose();
            // }
          }
        }
      }
    },

    searchBtn: {
      handler (searchBtn) {
        switch (this.sreachType) {
          case "ps":
            this.deviceTypeIds = "";
            this.getPsDetailOrDaysByPsIds();
            break;
        }

      }
    },
    resizeName: {
      handler () {
        this.myChart.resize();
      }
    },
    // immediate: true
  },
  created () {
    this.loading = false;
  },
  methods: {
    formatReport(){
      var _this = this;
      if(_this.calendarType=="date" ){
          switch (_this.$store.state.psTypeId) {
            case 3:
              //削峰（AC）
                _this.psReportParam.title=[this.$t("ReportDownloads.ps.rq"),this.$t("ReportDownloads.ps.cfdgl"),this.$t("ReportDownloads.ps.xfgl"),'SOC（%）']
                _this.psReportParam.val=['getdatetime','pac','powerCon','soc']
              break
            case 2:
              //过积载
               _this.psReportParam.title=[this.$t("ReportDownloads.ps.rq"),this.$t("ReportDownloads.ps.gffdgl"),this.$t("ReportDownloads.ps.cfdgl"),'SOC（%）']
               _this.psReportParam.val=['getdatetime','pvPac','pac','soc']
              break;
            case 1:
              //自家消费
               _this.psReportParam.title=[this.$t("ReportDownloads.ps.rq"),this.$t("ReportDownloads.ps.gffdgl"),this.$t("ReportDownloads.ps.cfdgl"),this.$t("ReportDownloads.ps.yhdbgl"),this.$t("ReportDownloads.ps.xfgl"),this.$t("ReportDownloads.ps.soc")]
               _this.psReportParam.val=['getdatetime','pvPac','pac','gridPac','powerCon','soc']
              break;
          }  
       

      }else if(_this.calendarType=="month"){
          switch (_this.$store.state.psTypeId) {
            case 3:
              //削峰（AC）
                _this.psReportParam.title=[this.$t("ReportDownloads.ps.rq"),this.$t("ReportDownloads.ps.cdl"),this.$t("ReportDownloads.ps.fdl"),this.$t("ReportDownloads.ps.xfdl")]
                _this.psReportParam.val=['getdatetime','inTotal','outTotal','totalpac']
              break
            case 2:
              //过积载
               _this.psReportParam.title=[this.$t("ReportDownloads.ps.rq"),this.$t("ReportDownloads.ps.gffdl"),this.$t("ReportDownloads.ps.mdl")]
               _this.psReportParam.val=['getdatetime','pvTotal','totalpac']
               
              break;
            case 1:
              //自家消费
               _this.psReportParam.title=[this.$t("ReportDownloads.ps.rq"),this.$t("ReportDownloads.ps.mdl2"),this.$t("ReportDownloads.ps.mdl"),this.$t("ReportDownloads.ps.cdl"),this.$t("ReportDownloads.ps.fdl"),this.$t("ReportDownloads.ps.gffdl"),this.$t("ReportDownloads.ps.xfdl")]
               _this.psReportParam.val=['getdatetime','gridEngery','sendEngery','inTotal','outTotal','pvTotal','totalpac']
              break;
          }  
        }
        
    },
     //电站报表下载
    formatJson(filterVal, jsonData) {
      var _this=this;
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'getdatetime') {
          if(_this.calendarType=="month" ){
              return v[j]!=null?v[j].split('-')[2]:'-'
          }
          else {
              return v[j]
          }
          
        }else if(j === 'inTotal' || j ==='outTotal' || j ==='gridEngery' || j ==='sendEngery'){
              return v[j]!= null ? Math.abs(v[j]) :""
        }else {
              return v[j]
        }
        
      }))
    },
    downPsReport(){
      var _this=this;
      _this.formatReport();

     
    
  
      if(_this.calendarType=="month" ){
        _this.psReportParam.name=window.sessionStorage.getItem("psName") + this.$moment(this.searchdate).format("YYYY-MM") + this.$moment().format(" HHmmss");
      }else{
        _this.psReportParam.name=window.sessionStorage.getItem("psName") + this.$moment(this.searchdate).format("YYYY-MM-DD") + this.$moment().format(" HHmmss");
      }
        _this.psReportParam.loading=true
        import('@/assets/vendor/excel/Export2Excel').then(excel => {
          const tHeader = _this.psReportParam.title
          const filterVal = _this.psReportParam.val
          const list = _this.psReportParam.result
          if(list!=null && list.length > 0){
            const data = _this.formatJson(filterVal, list)

            if(_this.calendarType=="month" ){
              var total= JSON.parse(JSON.stringify(data[0])) 
              total[0]=this.$t("ReportDownloads.total")
              $(data).each(function(i,val){
                  if(i>0){
                    $(val).each(function(x,t){
                      if(x>0){
                          total[x]+=t
                      }
                    })
                  }  
              })
              data.push(total);
            }
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename:_this.psReportParam.name,
              bookType: 'csv'
            })
          }
           _this.psReportParam.loading=false
        })
    },
    //电站查询日、月数据
    async getPsDetailOrDaysByPsIds () {
      var _this = this;
      this.loading = true;
      this.charDatas = [];
      let userId = window.sessionStorage.getItem("userId");
      if (this.$store.state.psId != undefined && this.$store.state.psId != null && this.$store.state.psId != "") {
        let psData = {};
        let url = ""
        if (this.calendarType == "date") {
          url = '/service-device/pssys/getDeviceDayPacsByPsid?psId=' + this.$store.state.psId + '&searchDate=' + this.searchdate + '&psTypeId=' + this.$store.state.psTypeId;
        } else if (this.calendarType == "month") {
          url = '/service-device/pssys/getDeviceMonthAmountByPsid?psId=' + this.$store.state.psId + '&searchDate=' + this.searchdate + '&psTypeId=' + this.$store.state.psTypeId;
        }
        const { data: res } = await this.$http.get(url, {});

        if (res.status == 200) {
         
          for (let i = 0; i < res.data.length; i++) {
            let getdatetime = res.data[i]["getdatetime"];
            if (getdatetime != undefined && getdatetime != null && getdatetime.indexOf(" ") >= 0) {
              let gettime = getdatetime.split(" ")[1];
              let gettimeArr = gettime.split(":");
              res.data[i]["getdatetime"] = getdatetime.split(" ")[0] + " " + gettimeArr[0] + ":" + gettimeArr[1] + ":00";
            }
          }
          this.charDatas = [];
          psData = { "deviceTypeId": "", "data": res.data }
           _this.psReportParam.result = res.data //电站报表赋值
           if(_this.psReportParam.result==null || _this.psReportParam.result.length==0){
              _this.psReportParam.isDis=true
           }else{
             _this.psReportParam.isDis=false
           }
          this.charDatas.push(psData);
          this.$emit("dataLoading", false);
          this.detailChar();
        } else if (res.status == 564) {
          swal({
            //提示的标题
            title: _this.$t("interfaceMsg.600"),
            //提示的文本
            text: _this.$t("interfaceMsg.564"),
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("login.msg.determine") //'确定'
          });
        }
      }
      this.loading = false;
      console.info("charPsDatas", this.charDatas)

    },
    unique (array) {
      let n = {}, r = [], len = array.length, val, type;
      for (let i = 0; i < array.length; i++) {
        val = array[i];
        type = typeof val;
        if (!n[val]) {
          n[val] = [type];
          r.push(val);
        } else if (n[val].indexOf(type) < 0) {
          n[val].push(type);
          r.push(val);
        }
      }
      return r;
    },
    detailChar () {
      var _this = this;
      let devCharDatas = [];
      let yAxis = [];
      let repeatUnit = [];
      let xAxis = [];
      let tooltip = {};
      if (this.charDatas != null && this.charDatas.length > 0) {
        if (this.sreachTypes == "ps") {
          this.keyDatas[0]["visible"] = true;
        }
        for (let i = 0; i < this.keyParams.length; i++) {
          for (let j = 0; j < this.keyDatas.length; j++) {
            if (this.keyDatas[j]["visible"]) {
              if (this.keyDatas[j]["value"] == Object.keys(this.keyParams[i])[1]) {
                for (var k = 0; k < this.keyParams[i][this.keyDatas[j]["value"]].length; k++) {
                  let unitObj = {};
                  unitObj["deviceTypeId"] = this.keyParams[i]["deviceTypeId"];
                  for (var a = 0; a < this.units.length; a++) {
                    if (unitObj["deviceTypeId"] == this.units[a]["deviceTypeId"]) {
                      let names = this.keyDatas[j]["value"];
                      let paraname = this.keyParams[i][names][k]["value"];
                      unitObj["label"] = this.keyParams[i][names][k]["label"]
                      unitObj["value"] = paraname;
                      unitObj["unitName"] = this.units[a]["unit"][paraname];
                      unitObj["group"] = this.keyParams[i][names][k]["group"];
                      repeatUnit.push(unitObj);
                    }
                  }
                }
              }
            }
          }
        }

        //电站类型是过急载就不加载用户消费功率
        // if (this.$store.state.psTypeId == "2") {
        //   let repeatUnitLx = repeatUnit;
        //   repeatUnit = [];
        //   let j = 0;
        //   let ignore = "";
        //   switch (this.calendarType) {
        //     case "month":
        //       ignore = "totalpac";
        //       break
        //     case "date":
        //       ignore = "powerCon";
        //       break;
        //   }
        //   for (let i = 0; i < repeatUnitLx.length; i++) {
        //     if (repeatUnitLx[i].value != ignore) {
        //       repeatUnit[j] = repeatUnitLx[i]
        //       j++;
        //     }
        //   }

        // }

        console.info("repeatUnit", repeatUnit)

        for (let i = 0; i < repeatUnit.length; i++) {

          let yAixIndexs = {};
          let paramName = repeatUnit[i]["value"];
          let paramLable = this.$t(repeatUnit[i]["label"]);
          yAixIndexs[repeatUnit[i]["unitName"]] = i;
          let unit = repeatUnit[i]["unitName"];
          let yAxisName;
          for (let j = 0; j < this.yAxisNams.length; j++) {
            if (this.yAxisNams[j]["deviceTypeId"] == repeatUnit[i]["deviceTypeId"]) {
              yAxisName = this.yAxisNams[j]["yAxisName"];
            }
          }

          let yAxiName = this.$t(yAxisName[unit]);
          if (this.calendarType == "month") {
            unit = "kWh"
          }
          let yAxi = {
            type: 'value',
            //name: yAxiName + '(' + unit + ')',
            position: 'left',
            axisLabel: {
              formatter: '{value} ' + unit
            },
            splitLine: {
              show: false,
            }
          }

          yAxi["position"] = "";
          yAxi["offset"] = 0;
          yAxis.push([repeatUnit[i]["value"], repeatUnit[i]["group"], yAxi]);

          for (let j = 0; j < this.charDatas.length; j++) {
            let charDataObj = this.charDatas[j]["data"];
            let serieData = [];
            let serieName = paramLable;
            let yIndex = [];
            for (var a = 0; a < this.units.length; a++) {
              if (this.units[a]['deviceTypeId'] == this.charDatas[j]["deviceTypeId"]) {
                let name = this.units[a]["unit"][paramName]
                yIndex = yAixIndexs[name]
              }
            }
            for (let f = 0; f < charDataObj.length; f++) {
              if (this.calendarType == "month" && charDataObj[f]["getdatetime"].indexOf(" ") >= 0) {
                charDataObj[f]["getdatetime"] = charDataObj[f]["getdatetime"].split(" ")[0];
              }
              for (let item in charDataObj[f]) {
                if (item == "sendEngery" && charDataObj[f]["sendEngery"] != null && parseInt(charDataObj[f]["sendEngery"]) > 0) {
                  charDataObj[f]["sendEngery"] = -1 * parseInt(charDataObj[f]["sendEngery"]);
                }
                if (item == "inTotal" && charDataObj[f]["inTotal"] != null && parseInt(charDataObj[f]["inTotal"]) > 0) {
                  charDataObj[f]["inTotal"] = -1 * parseInt(charDataObj[f]["inTotal"]);

                }
              }
              serieData.push([charDataObj[f]["getdatetime"], charDataObj[f][paramName]])
            }
            let serie = {
              name: '-',
              type: 'line',
              smooth: false,
              yAxisIndex: 0,
              data: [],
              emphasis: {
                focus: 'series'
              },
            }

            serie["keyvalue"] = repeatUnit[i]["value"];
            //console.info("33333333333",serie["keyvalue"])
            if (serie["keyvalue"] == "soc" && this.calendarTypes == "date") {
              serie["areaStyle"] = {
                opacity: 0.3
              }
            }
            serie["name"] = serieName;
            serie["yAxisIndex"] = yIndex;
            serie["data"] = serieData;
            serie["group"] = repeatUnit[i]["group"]
            devCharDatas.push(serie);
          }
        }

        console.info("devCharDatas", devCharDatas)

        if (this.deviceTypeIds == "3,") {
          let arrParam;
          for (let i = 0; i < devCharDatas.length; i++) {
            //alert(devCharDatas[i]["keyvalue"])
            devCharDatas[i]["status"] = true;
            switch (devCharDatas[i]["keyvalue"]) {
              case "mcpList":
              case "mvMaxList":
              case "mvMinList":
              case "mtMaxList":
              case "mtMinList":
              case "msocList":
                let dxdata = [];
                // let serieData = [];
                // let yIndex = parseInt(devCharDatas[i]["yAxisIndex"]);
                let keyname = devCharDatas[i]["keyvalue"];
                let counts = 0;
                let lsdata = [];
                let yAxi = [];
                arrParam = [];

                if (devCharDatas[i]["data"].length > 0) {
                  dxdata = devCharDatas[i];
                  yAxi = yAxis[i];
                  devCharDatas[i]["status"] = false;
                  // devCharDatas.splice(mm,1);
                  // yAxis.splice(mm,1);
                  for (let k = 0; k < dxdata["data"].length; k++) {
                    let getdatetime = dxdata["data"][k][0];
                    if (dxdata["data"][k][1] != undefined && dxdata["data"][k][1].length > 0) {
                      counts = dxdata["data"][k][1].length;
                      for (var j = 0; j < dxdata["data"][k][1].length; j++) {
                        let objJosn = {};
                        objJosn["name"] = "rack" + (j + 1);
                        objJosn["getdatetime"] = getdatetime;
                        objJosn["value"] = dxdata["data"][k][1][j];
                        lsdata.push(objJosn);
                      }
                    } else {
                      counts = 0;
                    }
                  }


                  if (counts > 0) {
                    for (let k = 0; k < counts; k++) {
                      let datas = []
                      for (let f = 0; f < lsdata.length; f++) {
                        let name = "rack" + (k + 1);
                        if (name == lsdata[f]["name"]) {
                          datas.push([lsdata[f]["getdatetime"], lsdata[f]["value"]])
                        }
                      }
                      let serie = {
                        name: '-',
                        type: 'line',
                        smooth: false,
                        yAxisIndex: 0,
                        data: [],
                        emphasis: {
                          focus: 'series'
                        },
                      }

                      serie["keyvalue"] = keyname + "" + (k + 1);
                      serie["name"] = "rack" + (k + 1) + " " + (dxdata["name"]);
                      serie["yAxisIndex"] = 0;
                      //serieData.push([getdatetime, dxdata["data"][k][1][j]])
                      serie["data"] = datas;
                      serie["group"] = dxdata["group"]
                      devCharDatas.push(serie);
                      yAxis.push(yAxi)
                    }
                  }
                }


                //console.info("lsdata",lsdata)
                break;
            }
          }

          let lsdevCharDatas = devCharDatas;
          devCharDatas = [];
          for (let i = 0; i < lsdevCharDatas.length; i++) {
            if (lsdevCharDatas[i]["status"]) {
              devCharDatas.push(lsdevCharDatas[i]);
            }
          }
        }



        if (devCharDatas.length > 0) {
          devCharDatas = this.sortByKey(devCharDatas, 'group')

          let group;
          let lsYAxis = yAxis;
          let position = "left";
          let offset = 0;
          group = devCharDatas[0]["group"];
          yAxis = [];
          let n = 0;
          for (let i = 0; i < devCharDatas.length; i++) {
            if (i == 0 || group != devCharDatas[i]["group"]) {
              group = devCharDatas[i]["group"]
              for (let j = 0; j < devCharDatas.length; j++) {
                if (group == devCharDatas[j]["group"]) {
                  devCharDatas[j]["yAxisIndex"] = n;
                }
                if (j < lsYAxis.length) {
                  if (group == lsYAxis[j][1] && devCharDatas[i]["keyvalue"] == lsYAxis[j][0]) {
                    lsYAxis[j][2]["position"] = position;
                    lsYAxis[j][2]["offset"] = offset;
                    yAxis.push(lsYAxis[j][2]);
                  }
                }


                if (j > 0 && j == devCharDatas.length - 1) {
                  if (position == "left") {
                    if (n == 0) {
                      position = "right";
                    }
                    if (n == 2) {
                      position = "right";
                      offset = -100;
                    }
                  } else {
                    if (n == 1) {
                      position = "left";
                      offset = -100;
                    }
                    if (n == 3) {
                      position = "left";
                      offset = -200;
                    }
                  }
                  n = n + 1;
                }
              }
            }
          }
        }

        //设置x轴标签是不是主动显示
        let nochoose = {};
        if (this.calendarType == "month") {
          let name = this.$t("charts.overload.overPac")
          nochoose[name] = true
        } else {
          let name = this.$t("charts.overload.overPower")
          nochoose[name] = true
        }

        let legends = {
          orient: 'horizontal',
          bottom: 0,
          icon: 'stack',
          selected: nochoose
        }

        switch (this.sreachTypes) {
          case "ps":
            if (this.calendarType == "date") {
              this.startdate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(this.searchdate).getDate()) + " 00:00:00";
              if (new Date(this.startdate).toLocaleDateString() == new Date().toLocaleDateString())
                this.enddate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(this.searchdate).getDate()) + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":00";
              else
                this.enddate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(this.searchdate).getDate()) + " 23:59:59";
              xAxis = [
                {
                  // min: this.startdate,
                  // max: this.enddate,
                  //type: 'category',
                  splitNumber: 24,
                  type: 'time',
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    formatter: function (time) {

                      let dt = new Date(time);
                      let hours = dt.getHours()
                      hours = hours < 10 ? '0' + hours : hours;
                      let minute = dt.getMinutes()
                      minute = minute < 10 ? '0' + minute : minute;
                      return hours + ":" + minute;
                    }
                  },
                  splitLine: {
                    show: false,
                  }

                }
              ]

              tooltip = {
                trigger: 'axis',
                axisPointer: { type: 'cross' },
                formatter: function (params) {
                  let time = params[0].axisValueLabel.split(":");
                  var relVal = time[0] + ":" + time[1];
                  for (var i = 0, l = params.length; i < l; i++) {
                    relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value[1]
                  }
                  return relVal
                }
              }

              //设置KW单位最大最小值
              this.maxValueKW = 0;
              this.minValueKW = 0;
              this.maxValueSoc = 0;
              this.minValueSoc = 0;
              for (let i = 0; i < devCharDatas.length; i++) {
                let dataValue = devCharDatas[i]["data"]
                if (dataValue != null) {
                  for (let j = 0; j < dataValue.length; j++) {
                     switch(devCharDatas[i]["keyvalue"]){
                     case "pvPac":
                     case "pac":
                     case "gridPac":
                     case "powerCon":
                           this.maxValueKW = parseFloat(dataValue[j][1]) > this.maxValueKW ? parseFloat(dataValue[j][1]) : this.maxValueKW;
                           this.minValueKW = parseFloat(dataValue[j][1]) < this.minValueKW ? parseFloat(dataValue[j][1]) : this.minValueKW;
                       break;
                     case "soc":
                           this.maxValueSoc = parseFloat(dataValue[j][1]) > this.maxValueSoc ? parseFloat(dataValue[j][1]) : this.maxValueSoc;
                           this.minValueSoc = parseFloat(dataValue[j][1]) < this.minValueSoc ? parseFloat(dataValue[j][1]) : this.minValueSoc;
                       break;
                     }
                  }
                }
              }

              let jsonMaxMinKW = getMaxMinValue(this.maxValueKW,this.minValueKW)
              let jsonMaxMinSoc = getMaxMinValue(this.maxValueSoc,this.minValueSoc)
              this.maxValueKW = jsonMaxMinKW["max"];
              this.minValueKW = jsonMaxMinKW["min"];
              this.maxValueSoc = jsonMaxMinSoc["max"];
              this.minValueSoc = jsonMaxMinSoc["min"];
              if(jsonMaxMinKW["flag"] || jsonMaxMinSoc["flag"]){
                let jsonMaxMinKW = getMaxMinSet(this.maxValueKW,this.minValueKW)
                this.maxValueKW = jsonMaxMinKW["max"];
                this.minValueKW = jsonMaxMinKW["min"];
                let jsonMaxMinSoc = getMaxMinSet(this.maxValueSoc,this.minValueSoc)
                this.maxValueSoc = jsonMaxMinSoc["max"];
                this.minValueSoc = jsonMaxMinSoc["min"];
              }


              for (let i = 0; i < yAxis.length; i++) {
               switch(yAxis[i].position){
                   case "left":
                      yAxis[i]["min"] = this.minValueKW;
                      yAxis[i]["max"] = this.maxValueKW;
                     break;
                   case "right":
                      yAxis[i]["min"] = this.minValueSoc;
                      yAxis[i]["max"] = this.maxValueSoc;
                     break
               }
              }
            } else if (this.calendarType == "month") {

              this.startdate = this.formatDate(new Date(this.searchdate).getFullYear(), (new Date(this.searchdate).getMonth() + 1), 1);
              this.enddate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, 0).getDate());


              let datesData = []
              let lastDate;
              //let colorArr =  ['#007bff','#91cc75','#fc8452','#9a60b4','#ea7ccc','#990000','#D94B40','#5470C6','#02642C'];
              //选中本月
              if (new Date().getFullYear() == new Date(this.searchdate).getFullYear() && (new Date().getMonth() + 1) == (new Date(this.searchdate).getMonth() + 1)) {
                let dd = new Date().getDate() == 31 ? 0 : new Date().getDate();
                lastDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, dd).getDate();
              }
              else {
                lastDate = new Date(new Date(this.enddate).getFullYear(), new Date(this.enddate).getMonth() + 1, 0).getDate();
              }



              for (let i = 0; i < lastDate; i++) {
                datesData.push(this.formatDate(new Date(this.startdate).getFullYear(), (new Date(this.startdate).getMonth() + 1), (i + 1)))
              }

              for (let i = 0; i < devCharDatas.length; i++) {
                if (devCharDatas[i]["keyvalue"] != "electricityCon") {
                  devCharDatas[i]["type"] = 'bar';
                  devCharDatas[i]["stack"] = 'one';
                }
                // let normal={
                //   normal:{
                //     color: colorArr[i]
                //   }
                // }
                // devCharDatas[i]["itemStyle"] = normal;
              }

              let lsArr = [];
              let nn = 0;
              let titleArr = [];
              for (let i = devCharDatas.length - 1; i >= 0; i--) {
                titleArr[nn] = devCharDatas[nn].name
                lsArr[nn] = devCharDatas[i];
                nn++
              }

              legends = {
                data: titleArr,
                orient: 'horizontal',
                bottom: 0,
                icon: 'stack',
                selected: nochoose
              }
              devCharDatas = lsArr
              console.info("monthdevCharDatas", devCharDatas)

              xAxis = [
                {
                  data: datesData,
                  axisTick: {
                    alignWithLabel: true
                  },
                  axisLabel: {
                    formatter: function (time) {

                      let dt = new Date(time);
                      let months = (dt.getMonth() + 1)
                      months = months < 10 ? '0' + months : months;
                      let days = dt.getDate();
                      days = days < 10 ? '0' + days : days;
                      return days;
                    }
                  },
                  splitLine: {
                    show: false,
                  }

                }
              ]
              tooltip = {
                trigger: 'axis',
                axisPointer: { type: 'cross' },
                formatter: function (params) {
                  let time; var relVal;
                  time = params[0].axisValueLabel.split(":");
                  relVal = time[0];
                  switch (_this.calendarType) {
                    case "month":
                      for (var i = params.length - 1; i >= 0; i--) {
                        relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Math.abs(params[i].value[1])
                      }
                      break;
                    default:
                      for (var i = 0, l = params.length; i < l; i++) {
                        relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + Math.abs(params[i].value[1])
                      }
                      break;
                  }
                  return relVal
                }
              }



            }


            break;
          // case "dev":
          //   if (this.calendarType == "date") {
          //     this.startdate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(this.searchdate).getDate()) + " 00:00:00";
          //     this.enddate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(this.searchdate).getDate()) + " 23:59:59";
          //     xAxis = [
          //       {
          //         min: this.startdate,
          //         max: this.enddate,
          //         //type: 'category',
          //         splitNumber: 24,
          //         type: 'time',
          //         axisTick: {
          //           alignWithLabel: true
          //         },
          //         axisLabel: {
          //           formatter: function (time) {

          //             let dt = new Date(time);
          //             let hours = dt.getHours()
          //             hours = hours < 10 ? '0' + hours : hours;
          //             let minute = dt.getMinutes()
          //             minute = minute < 10 ? '0' + minute : minute;
          //             return hours + ":" + minute;
          //           }
          //         },
          //         splitLine: {
          //           show: false,
          //         }

          //       }
          //     ]

          //     tooltip = {
          //       trigger: 'axis',
          //       axisPointer: { type: 'cross' },
          //       formatter: function (params) {
          //         let time = params[0].axisValueLabel.split(":");
          //         var relVal = time[0] + ":" + time[1];
          //         for (var i = 0, l = params.length; i < l; i++) {
          //           relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value[1]
          //         }
          //         return relVal
          //       }
          //     }
          //   } else if (this.calendarType == "month") {

          //     this.startdate = this.formatDate(new Date(this.searchdate).getFullYear(), (new Date(this.searchdate).getMonth() + 1), 1);
          //     this.enddate = this.formatDate(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, new Date(new Date(this.searchdate).getFullYear(), new Date(this.searchdate).getMonth() + 1, 0).getDate());


          //     let datesData = []
          //     let lastDate = new Date(new Date(this.enddate).getFullYear(), new Date(this.enddate).getMonth() + 1, 0).getDate();

          //     for (let i = 0; i < lastDate; i++) {
          //       datesData.push(this.formatDate(new Date(this.startdate).getFullYear(), (new Date(this.startdate).getMonth() + 1), (i + 1)))
          //     }
          //     if (this.deviceTypeIds != null && this.deviceTypeIds != "loading") {
          //       switch (this.deviceTypeIds) {
          //         case "1,"://电量（柱状图）
          //         case "2,"://电量（柱状图）
          //         case "5,"://电量（柱状图）
          //         case "1,2,"://电量（柱状图）
          //         case "2,5"://电量（柱状图）
          //         case "1,5"://电量（柱状图）
          //         case "1,2,5"://电量（柱状图）
          //           for (let i = 0; i < devCharDatas.length; i++) {
          //             devCharDatas[i]["type"] = 'bar';
          //             devCharDatas[i]["stack"] = 'one';
          //           }

          //           xAxis = [
          //             {
          //               data: datesData,
          //               axisTick: {
          //                 alignWithLabel: true
          //               },
          //               axisLabel: {
          //                 formatter: function (time) {

          //                   let dt = new Date(time);
          //                   let months = (dt.getMonth() + 1)
          //                   months = months < 10 ? '0' + months : months;
          //                   let days = dt.getDate();
          //                   days = days < 10 ? '0' + days : days;
          //                   return days;
          //                 }
          //               },
          //               splitLine: {
          //                 show: false,
          //               }

          //             }
          //           ]

          //           break;
          //         default:
          //           xAxis = [
          //             {
          //               //data: datesData,
          //               min: this.startdate,
          //               max: this.enddate,
          //               splitNumber: lastDate,
          //               type: 'time',
          //               axisTick: {
          //                 alignWithLabel: true
          //               },
          //               axisLabel: {
          //                 formatter: function (time) {

          //                   let dt = new Date(time);
          //                   let months = (dt.getMonth() + 1)
          //                   months = months < 10 ? '0' + months : months;
          //                   let days = dt.getDate();
          //                   days = days < 10 ? '0' + days : days;
          //                   return months + "/" + days;
          //                 }
          //               },
          //               splitLine: {
          //                 show: false,
          //               }

          //             }
          //           ]
          //           break;
          //       }
          //     }

          //     tooltip = {
          //       trigger: 'axis',
          //       axisPointer: { type: 'cross' },
          //       formatter: function (params) {
          //         let time = params[0].axisValueLabel.split(":");
          //         var relVal = time[0];
          //         for (var i = 0, l = params.length; i < l; i++) {
          //           relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value[1]
          //         }
          //         return relVal
          //       }
          //     }
          //   }
          //   break;

        }

        //设置电量柱状图
        console.info("yAxis", yAxis);
        console.info("devCharDatas", devCharDatas);

        //  yAxis = [{"type":"value","name":"功率(kW)","position":"left","axisLabel":{"formatter":"{value} kW"},"splitLine":{"show":false}}]
        //  devCharDatas =  [{"name":"充放/电功率-宫崎PCS","type":"line","smooth":true,"yAxisIndex":0,"data":[["2022-02-23 00:05:21",51.067],["2022-02-23 00:10:57",51.036],["2022-02-23 00:16:34",51.012],["2022-02-23 00:22:11",50.985],["2022-02-23 00:27:47",50.959],["2022-02-23 00:33:24",50.929],["2022-02-23 00:39:00",50.898],["2022-02-23 00:44:36",50.873],["2022-02-23 00:50:12",50.847],["2022-02-23 00:55:49",50.967],["2022-02-23 01:01:26",51.082],["2022-02-23 01:07:03",51.055],["2022-02-23 01:12:39",51.021],["2022-02-23 01:18:16",50.998],["2022-02-23 01:23:52",50.971],["2022-02-23 01:29:28",50.944],["2022-02-23 01:35:02",50.912],["2022-02-23 01:40:39",50.882],["2022-02-23 01:46:15",50.846],["2022-02-23 01:51:52",51.097],["2022-02-23 01:57:28",51.059],["2022-02-23 02:03:05",51.02],["2022-02-23 02:08:42",50.983],["2022-02-23 02:14:18",50.942],["2022-02-23 02:19:57",50.898],["2022-02-23 02:25:33",50.858],["2022-02-23 02:31:10",50.883],["2022-02-23 02:36:46",51.049],["2022-02-23 02:42:23",51.01],["2022-02-23 02:48:00",50.971],["2022-02-23 02:53:36",50.918],["2022-02-23 02:59:12",50.874],["2022-02-23 03:04:48",50.826],["2022-02-23 03:10:24",50.988],["2022-02-23 03:16:01",51.009],["2022-02-23 03:21:37",50.959],["2022-02-23 03:27:13",50.907],["2022-02-23 03:32:53",0.014]],"emphasis":{"focus":"series"}}]


        // this.startdate =  "2022-02-23 00:00:00";
        // this.enddate = "2022-02-23 23:59:59";


        var chartDom = document.getElementById('device-indoor-temp-char-detail');
        this.myChart = this.$echarts.init(chartDom, 'macarons');
        var option;

        option = {
          //图形上下右左边距设置
          grid: {
            left: '3%',
            right: '2.5%',
            bottom: 55,
            containLabel: true
          },
          tooltip: tooltip,
          legend: legends,
          xAxis: xAxis,
          yAxis: yAxis,
          series: devCharDatas,
        };

        switch (_this.calendarType) {
          case "month":
            break;
          default:
            // option["color"] = ['#007bff','#91cc75','#fc8452','#9a60b4','#ea7ccc','#990000','#D94B40','#5470C6','#02642C']
            break;
        }

        option && this.myChart.setOption(option, true);
        return true;
      }
    },
    //数组对象排序
    sortByKey (array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      })
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    }
  },


}
</script>